import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  TranslatableFields,
} from 'react-admin';

export const CategoryShow = (props) => (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="uuid"/>
        <TextField source="uuid_entreprise"/>
        <TextField source="mode_service"/>
        <TextField source="mode_consigne"/>
        <TextField source="mode_livraison"/>
        <TextField source="rank"/>
        <TranslatableFields locales={['fr', 'en']} defaultLocale="fr">
          <TextField source="title"/>
          <TextField source="content"/>
        </TranslatableFields>
        <TextField source="min_delay"/>
        <TextField source="tva"/>
        <TextField source="picto"/>
        <TextField source="enabled"/>
      </SimpleShowLayout>
    </Show>
);
