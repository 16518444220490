import {fetchUtils} from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }
  const token = localStorage.getItem('auth');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const DataProvider = {
  getList: (resource, params) => {
    let url = `${apiUrl}/${resource}`;
    const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
    if (resource === 'product' || resource === 'category') {
      url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}`;
    }
    if (params.pagination.page > 1) {
      url += `?page=${params.pagination.page}`;
    }
    return httpClient(url)
        .then(({json}) => ({
          data: json.data.map(item => {
            return {id: item.uuid, ...item};
          }),
          total: json.meta.total,
        }));
  },
  getOne: (resource, params) => {
    let url = `${apiUrl}/${resource}/${params.id}`;
    const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
    if (resource === 'product' || resource === 'category') {
      url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}/${params.id}`;
    }
    return httpClient(url).then(({json}) => ({
      data: {id: json.data.uuid, ...json.data},
    }));
  },
  getMany: (resource, params) => {
    params.ids.forEach(id => {
      let url = `${apiUrl}/${resource}/${id}`;
      const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
      if (resource === 'product' || resource === 'category') {
        url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}/${id}`;
      }
      httpClient(url).then(() => {});
    });
    return Promise.resolve({data: params.ids.map(id => ({id: id}))});
  },
  update: (resource, params) => {
    let url = `${apiUrl}/${resource}/${params.id}`;
    const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
    if (resource === 'product' || resource === 'category') {
      url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}/${params.id}`;
    }
    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: {id: json.data.uuid, ...json.data}}));
  },
  create: (resource, params) => {
    let url = `${apiUrl}/${resource}`;
    const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
    if (resource === 'product' || resource === 'category') {
      url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}`;
    }
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({
      data: {id: json.data.uuid, ...json.data},
    }));
  },
  delete: (resource, params) => {
    let url = `${apiUrl}/${resource}/${params.id}`;
    const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
    if (resource === 'product' || resource === 'category') {
      url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}/${params.id}`;
    }
    return httpClient(url, {
      method: 'DELETE',
    }).then(() => ({data: {id: params.id}}));
  },
  deleteMany: (resource, params) => {
    params.ids.forEach(id => {
      let url = `${apiUrl}/${resource}/${id}`;
      const uuid_entreprise = JSON.parse(localStorage.getItem('entreprise')).uuid;
      if (resource === 'product' || resource === 'category') {
        url = `${apiUrl}/entreprise/${uuid_entreprise}/${resource}/${id}`;
      }
      httpClient(url, {
        method: 'DELETE',
      }).then(() => {});
    });
    return Promise.resolve({data: params.ids});
  },
};

export default DataProvider;
