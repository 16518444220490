import * as React from 'react';
import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  List,
  ShowButton,
  TextField,
} from 'react-admin';

export const CategoryList = (props) => (
    <List {...props}>
      <Datagrid>
        <TextField source="uuid"/>
        <TextField source="title.fr"/>
        <TextField source="rank"/>
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
);
