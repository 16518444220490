import * as React from 'react';
import {
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TranslatableInputs,
} from 'react-admin';

export const CategoryEdit = (props) => {
  return (
      <Edit {...props}>
        <SimpleForm margin="normal">
          <TextField source="uuid"/>
          <TextField source="uuid_entreprise"/>
          <SelectInput source="mode_service" choices={[
            {id: 'on', name: 'on'},
            {id: 'off', name: 'off'},
          ]} fullWidth />
          <SelectInput source="mode_consigne" choices={[
            {id: 'on', name: 'on'},
            {id: 'off', name: 'off'},
          ]} fullWidth />
          <SelectInput source="mode_livraison" choices={[
            {id: 'on', name: 'on'},
            {id: 'off', name: 'off'},
          ]} fullWidth />
          <NumberInput source="rank" fullWidth />
          <TranslatableInputs locales={['fr', 'en']} defaultLocale="fr">
            <TextInput source="title" resettable fullWidth/>
            <TextInput source="content" resettable fullWidth/>
          </TranslatableInputs>
          <NumberInput source="min_delay" fullWidth/>
          <NumberInput source="tva" fullWidth/>
          <TextInput source="picto" resettable fullWidth/>
          <SelectInput source="enabled" choices={[
            {id: 'on', name: 'on'},
            {id: 'off', name: 'off'},
          ]} fullWidth/>
        </SimpleForm>
      </Edit>
  );
};

