import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  TranslatableFields,
} from 'react-admin';

export const NotificationShow = (props) => (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="uuid"/>
        <TextField source="identifier"/>
        <TranslatableFields locales={['fr', 'en']} defaultLocale="fr">
          <TextField source="subject"/>
          <TextField source="content"/>
        </TranslatableFields>
      </SimpleShowLayout>
    </Show>
);
