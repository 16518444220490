const authProvider = {
  login: ({apiKey}) => {

    let formData = new FormData();
    formData.append('apikey', apiKey);

    const request = new Request(`${process.env.REACT_APP_API_URL}/auth/byApikey`,
        {
          method: 'POST',
          body: formData,
          headers: new Headers({
            'Accept': 'application/json',
          }),
        });

    return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(auth => {
          localStorage.setItem('auth', auth.access_token);
        })
        .catch(() => {
          throw new Error('Network error');
        });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      localStorage.removeItem('entreprise');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject(),
  logout: () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('entreprise');
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const request = new Request(`${process.env.REACT_APP_API_URL}/user/me`,
          {
            method: 'GET',
            headers: new Headers({
              'Accept': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('auth')}`,
            }),
          });

      return fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .then(response => {
            localStorage.setItem('entreprise', JSON.stringify(response.data.entreprises[0]));
            return Promise.resolve({
              id: response.data.uuid,
              fullName: response.data.name,
              avatar: `https://app.cleandrop.fr/${response.data.entreprises[0].logo}`,
            });
          })
          .catch(() => {
            throw new Error('Network error');
          });

    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: params => Promise.resolve(),
};

export default authProvider;
