import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  TranslatableFields,
} from 'react-admin';

export const ProductShow = (props) => (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="uuid"/>
        <TextField source="uuid_entreprise"/>
        <TextField source="uuid_category"/>
        <TextField source="part_ref"/>
        <TextField source="rank"/>
        <TranslatableFields locales={['fr', 'en']} defaultLocale="fr">
          <TextField source="title"/>
          <TextField source="content"/>
        </TranslatableFields>
        <TextField source="price"/>
        <TextField source="tva"/>
        <TextField source="temp"/>
        <TextField source="weight"/>
        <TextField source="drop_volume"/>
        <TextField source="min_height"/>
        <TextField source="min_depth"/>
        <TextField source="min_delay"/>
        <TextField source="picto"/>
        <TextField source="mobile_only"/>
        <TextField source="enabled"/>
      </SimpleShowLayout>
    </Show>
);
