import * as React from 'react';
import {
  Edit,
  SelectInput,
  SimpleForm, TextField,
  TextInput,
  TranslatableInputs,
} from 'react-admin';

export const NotificationEdit = (props) => {
  return (
      <Edit {...props}>
        <SimpleForm margin="normal">
          <TextField source="uuid"/>
          <SelectInput label="Identifier" source="identifier" choices={[
            {id: 'SMS_WelcomeCustomer', name: 'SMS_WelcomeCustomer'},
            {
              id: 'MAIL_WelcomeCustomer_HTML',
              name: 'MAIL_WelcomeCustomer_HTML',
            },
            {
              id: 'MAIL_WelcomeCustomer_TEXT',
              name: 'MAIL_WelcomeCustomer_TEXT',
            },
            {id: 'MAIL_WelcomeVigil_HTML', name: 'MAIL_WelcomeVigil_HTML'},
            {id: 'MAIL_WelcomeVigil_TEXT', name: 'MAIL_WelcomeVigil_TEXT'},
            {id: 'MAIL_WelcomeWorker_HTML', name: 'MAIL_WelcomeWorker_HTML'},
            {id: 'MAIL_WelcomeWorker_TEXT', name: 'MAIL_WelcomeWorker_TEXT'},
            {
              id: 'MAIL_WelcomeEntreprise_HTML',
              name: 'MAIL_WelcomeEntreprise_HTML',
            },
            {
              id: 'MAIL_WelcomeEntreprise_TEXT',
              name: 'MAIL_WelcomeEntreprise_TEXT',
            },
            {id: 'SMS_CodeLost', name: 'SMS_CodeLost'},
            {id: 'MAIL_CodeLost_HTML', name: 'MAIL_CodeLost_HTML'},
            {id: 'MAIL_CodeLost_TEXT', name: 'MAIL_CodeLost_TEXT'},
            {id: 'SMS_ReserveAdded', name: 'SMS_ReserveAdded'},
            {id: 'MAIL_ReserveAdded_HTML', name: 'MAIL_ReserveAdded_HTML'},
            {id: 'MAIL_ReserveAdded_TEXT', name: 'MAIL_ReserveAdded_TEXT'},
            {id: 'SMS_ReserveAccepted', name: 'SMS_ReserveAccepted'},
            {
              id: 'MAIL_ReserveAccepted_HTML',
              name: 'MAIL_ReserveAccepted_HTML',
            },
            {
              id: 'MAIL_ReserveAccepted_TEXT',
              name: 'MAIL_ReserveAccepted_TEXT',
            },
            {id: 'SMS_ReserveRefused', name: 'SMS_ReserveRefused'},
            {id: 'MAIL_ReserveRefused_HTML', name: 'MAIL_ReserveRefused_HTML'},
            {id: 'MAIL_ReserveRefused_TEXT', name: 'MAIL_ReserveRefused_TEXT'},
            {id: 'SMS_Withdrawing', name: 'SMS_Withdrawing'},
            {id: 'MAIL_Withdrawing_HTML', name: 'MAIL_Withdrawing_HTML'},
            {id: 'MAIL_Withdrawing_TEXT', name: 'MAIL_Withdrawing_TEXT'},
            {id: 'SMS_DroppedEntreprise', name: 'SMS_DroppedEntreprise'},
            {
              id: 'MAIL_DroppedEntreprise_HTML',
              name: 'MAIL_DroppedEntreprise_HTML',
            },
            {
              id: 'MAIL_DroppedEntreprise_TEXT',
              name: 'MAIL_DroppedEntreprise_TEXT',
            },
            {id: 'SMS_DroppedCustomer', name: 'SMS_DroppedCustomer'},
            {
              id: 'MAIL_DroppedCustomer_HTML',
              name: 'MAIL_DroppedCustomer_HTML',
            },
            {
              id: 'MAIL_DroppedCustomer_TEXT',
              name: 'MAIL_DroppedCustomer_TEXT',
            },
            {id: 'SMS_Processed', name: 'SMS_Processed'},
            {id: 'MAIL_Processed_HTML', name: 'MAIL_Processed_HTML'},
            {id: 'MAIL_Processed_TEXT', name: 'MAIL_Processed_TEXT'},
            {id: 'SMS_ReturnedCustomer', name: 'SMS_ReturnedCustomer'},
            {
              id: 'MAIL_ReturnedCustomer_HTML',
              name: 'MAIL_ReturnedCustomer_HTML',
            },
            {
              id: 'MAIL_ReturnedCustomer_TEXT',
              name: 'MAIL_ReturnedCustomer_TEXT',
            },
            {id: 'SMS_ReturnedSurrogate', name: 'SMS_ReturnedSurrogate'},
            {
              id: 'MAIL_ReturnedSurrogate_HTML',
              name: 'MAIL_ReturnedSurrogate_HTML',
            },
            {
              id: 'MAIL_ReturnedSurrogate_TEXT',
              name: 'MAIL_ReturnedSurrogate_TEXT',
            },
            {id: 'MAIL_Invoice_HTML', name: 'MAIL_Invoice_HTML'},
            {id: 'MAIL_Invoice_TEXT', name: 'MAIL_Invoice_TEXT'},
            {id: 'SMS_LockerCloseCustomer', name: 'SMS_LockerCloseCustomer'},
            {
              id: 'MAIL_LockerCloseCustomer_HTML',
              name: 'MAIL_LockerCloseCustomer_HTML',
            },
            {
              id: 'MAIL_LockerCloseCustomer_TEXT',
              name: 'MAIL_LockerCloseCustomer_TEXT',
            },
            {
              id: 'SMS_LockerCloseEntreprise',
              name: 'SMS_LockerCloseEntreprise',
            },
            {
              id: 'MAIL_LockerCloseEntreprise_HTML',
              name: 'MAIL_LockerCloseEntreprise_HTML',
            },
            {
              id: 'MAIL_LockerCloseEntreprise_TEXT',
              name: 'MAIL_LockerCloseEntreprise_TEXT',
            },
            {id: 'SMS_RetiredCustomer', name: 'SMS_RetiredCustomer'},
            {
              id: 'MAIL_RetiredCustomer_HTML',
              name: 'MAIL_RetiredCustomer_HTML',
            },
            {
              id: 'MAIL_RetiredCustomer_TEXT',
              name: 'MAIL_RetiredCustomer_TEXT',
            },
            {id: 'SMS_RetiredSurrogate', name: 'SMS_RetiredSurrogate'},
            {
              id: 'MAIL_RetiredSurrogate_HTML',
              name: 'MAIL_RetiredSurrogate_HTML',
            },
            {
              id: 'MAIL_RetiredSurrogate_TEXT',
              name: 'MAIL_RetiredSurrogate_TEXT',
            },
            {
              id: 'MAIL_Refund_HTML',
              name: 'MAIL_Refund_HTML',
            },
            {
              id: 'MAIL_Refund_TEXT',
              name: 'MAIL_Refund_TEXT',
            },
          ]} fullWidth/>
          <TranslatableInputs locales={['fr', 'en']} defaultLocale="fr">
            <TextInput source="subject" resettable fullWidth/>
            <TextInput source="content" resettable multiline fullWidth/>
          </TranslatableInputs>
        </SimpleForm>
      </Edit>
  );
};

