import * as React from 'react';
import {
  Create,
  NumberInput,
  SelectInput,
  SimpleForm,
  ReferenceInput,
  TextInput,
  TranslatableInputs,
} from 'react-admin';

export const ProductCreate = (props) => {
  return (
      <Create {...props}>
        <SimpleForm margin="normal">
          <ReferenceInput label="Category" source="uuid_category" reference="category" resettable fullWidth>
            <SelectInput optionText="title.fr" />
          </ReferenceInput>
          <TextInput source="part_ref" resettable fullWidth/>
          <NumberInput source="rank" fullWidth />
          <TranslatableInputs locales={['fr', 'en']} defaultLocale="fr">
            <TextInput source="title" resettable fullWidth/>
            <TextInput source="content" resettable fullWidth/>
          </TranslatableInputs>
          <NumberInput source="price" fullWidth/>
          <NumberInput source="tva" fullWidth/>
          <SelectInput source="temp" choices={[
            {id: 'Dry', name: 'Dry'},
            {id: 'Fresh', name: 'Fresh'},
            {id: 'Freeze', name: 'Freeze'},
            {id: 'Hot', name: 'Hot'},
          ]} fullWidth />
          <NumberInput source="weight" fullWidth/>
          <NumberInput source="drop_volume" fullWidth/>
          <NumberInput source="min_height" fullWidth/>
          <NumberInput source="min_depth" fullWidth/>
          <NumberInput source="min_delay" fullWidth/>
          <TextInput source="picto" resettable fullWidth/>
          <SelectInput source="mobile_only" choices={[
            {id: 'on', name: 'on'},
            {id: 'off', name: 'off'},
          ]} fullWidth />
          <SelectInput source="enabled" choices={[
            {id: 'on', name: 'on'},
            {id: 'off', name: 'off'},
          ]} fullWidth />
        </SimpleForm>
      </Create>
  );
};

