import {createMuiTheme} from '@material-ui/core/styles';
import * as React from 'react';
import {Admin, Resource} from 'react-admin';
import authProvider from './authProvider';
import {CategoryCreate} from './categories/CategoryCreate';
import {CategoryEdit} from './categories/CategoryEdit';
import {CategoryList} from './categories/CategoryList';
import {CategoryShow} from './categories/CategoryShow';
import Dashboard from './dashboard/Dashboard';
import dataProvider from './dataProvider';
import LoginPage from './LoginPage';
import {NotificationCreate} from './notifications/NotificationCreate';
import {NotificationEdit} from './notifications/NotificationEdit';
import {NotificationList} from './notifications/NotificationList';
import {NotificationShow} from './notifications/NotificationShow';
import {ProductCreate} from './products/ProductCreate';
import {ProductEdit} from './products/ProductEdit';
import {ProductList} from './products/ProductList';
import {ProductShow} from './products/ProductShow';

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

const App = () => (
    <Admin
        title="Smartlockers V2.0 API TESTING"
        dashboard={Dashboard}
        disableTelemetry
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
    >
      <Resource name="notification"
                show={NotificationShow}
                list={NotificationList}
                create={NotificationCreate}
                edit={NotificationEdit}/>
      <Resource name="category"
                show={CategoryShow}
                list={CategoryList}
                create={CategoryCreate}
                edit={CategoryEdit}/>
      <Resource name="product"
                show={ProductShow}
                list={ProductList}
                create={ProductCreate}
                edit={ProductEdit}/>
    </Admin>
);

export default App;
