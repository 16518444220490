import Card from '@material-ui/core/Card';
import * as React from 'react';
import {Title} from 'react-admin';

const Dashboard = () => (
    <Card>
      <Title title="Smartlockers V2.0 API TESTING"/>
    </Card>
);

export default Dashboard;
