import {Button, CardActions, CircularProgress} from '@material-ui/core';
import {createMuiTheme, makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {ThemeProvider} from '@material-ui/styles';
import {useSafeSetState} from 'ra-core';
import * as React from 'react';
import {Login, Notification, useLogin, useNotify} from 'react-admin';
import {Field, Form} from 'react-final-form';

const useStyles = makeStyles(
    (theme) => ({
      form: {
        padding: '0 1em 1em 1em',
      },
      input: {
        marginTop: '1em',
      },
      button: {
        width: '100%',
      },
      icon: {
        marginRight: theme.spacing(1),
      },
    }),
);

const Input = ({
  meta: {touched, error}, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginPage = (props) => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {
    const errors = {apiKey: undefined};
    if (!values.apiKey) {
      errors.apiKey = 'Ce champs est requis.';
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    login(values)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
              setLoading(false);
              notify('Invalid apiKey');
            },
        );
  };

  return (
      <ThemeProvider theme={createMuiTheme(props.theme)}>
        <Login>
          <Form
              onSubmit={submit}
              validate={validate}
              render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                      <div className={classes.input}>
                        <Field
                            autoFocus
                            id="apiKey"
                            name="apiKey"
                            component={Input}
                            label="Entreprise apiKey"
                            disabled={loading}
                        />
                      </div>
                    </div>
                    <CardActions>
                      <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          className={classes.button}
                          disabled={loading}
                      >
                        {loading && (
                            <CircularProgress
                                className={classes.icon}
                                size={18}
                                thickness={2}
                            />
                        )}
                        Connexion
                      </Button>
                    </CardActions>
                  </form>
              )}/>
          <Notification/>
        </Login>
      </ThemeProvider>
  );
};

export default LoginPage;
